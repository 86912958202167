import './style.css'

const ParallaxDivider = props => {
    return (
        <div className="divider" style={{ backgroundImage: `url(${props.image})` }}>
            <h1>{props.text}</h1>
        </div>
    )
}

export default ParallaxDivider;