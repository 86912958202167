import './style.css'

import Billie from '../../images/billie.png'
import InstagramLogo from '../../images/logo_instagram.png'
import VimeoLogo from '../../images/logo_vimeo.png'
import LinkedInLogo from '../../images/logo_linkedin.png'

const Link = props => {
    return <li className="link"><a href={ props.link }>{ props.text }</a></li>
}

const Nav = () => {
    return (
        <div className="nav">
            <img src={ Billie } alt="BillieJH" />
            <h1>BillieJH</h1>
            <div className="social">
                <a href="https://www.instagram.com/art_billiejh" target="_blank" rel="noreferrer"><img src={ InstagramLogo } alt="Instagram"/></a>
                <a href="https://www.vimeo.com/billiejh" target="_blank" rel="noreferrer"><img src={ VimeoLogo } alt="Vimeo"/></a>
                <a href="https://www.linkedin.com/in/billiejh/" target="_blank" rel="noreferrer"><img src={ LinkedInLogo } alt="LinkedIn"/></a>
            </div>
            <ul className="links">
                <Link text="About Me" link="/about" />
                <Link text="Projects" link="/work" />
                <Link text="Contact" link="/contact" />
            </ul>
        </div>
    )
}

export default Nav;