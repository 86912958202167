import React, { Component } from 'react'

import Intro from '../components/intro'
import ParallaxDivider from '../components/parallax-divider'
import Projects from '../components/projects'
import Contact from '../components/contact'

class Homepage extends Component {

    state = { }
    
    componentDidMount() {
        document.title = "BillieJH"
    }

    openModal = content => {
        console.log(content)
        this.setState({ modalActive: true, modalContent: content })
    }
    closeModal = props => {
        if(props.target.className === "modal-wrapper")
            this.setState({ modalActive: false })
    }

    render() {
        return (
            <div id="content">
                <Intro />
                <ParallaxDivider image="img/asmr.png" text="Projects" />
                <Projects />
                <ParallaxDivider image="img/satellite.png" text="Find Me" />
                <Contact />
            </div>
        )
    }
}

export default Homepage;