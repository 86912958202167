import './style.css'

import InstagramLogo from '../../images/logo_instagram.png'
import VimeoLogo from '../../images/logo_vimeo.png'
import LinkedInLogo from '../../images/logo_linkedin.png'

const Contact = () => {
    return (
        <div className="contact">
            <div className="content">
                <div className="links">
                    <a href="https://www.instagram.com/art_billiejh" target="_blank" rel="noreferrer">
                        <img src={ InstagramLogo } alt="Instagram"/>
                    </a>
                    <a href="https://www.vimeo.com/billiejh" target="_blank" rel="noreferrer">
                        <img src={ VimeoLogo } alt="Vimeo"/>
                    </a>
                    <a href="https://www.linkedin.com/in/billiejh/" target="_blank" rel="noreferrer">
                        <img src={ LinkedInLogo } alt="LinkedIn"/>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Contact;