import './style.css'

const Intro = () => {
    return (
        <div className="intro">
            <div className="content">
                <h1>Hi, I'm Billie</h1>
                <p>I'm a 20-something animator and illustrator based in London.</p>
                <p>Most of my work and specialty lies in 2D digital animation and motion graphics. </p>
                <p>I do dabble in the odd bit of 3D work however, and absolutely love the world of VR and the possibilities that hide there!</p>
                <p>If you're interested in working with me or have any questions, <a href="mailto:billiejhanimation@hotmail.com">feel free to reach out!</a></p>
            </div>
            <img src="../img/header.png" alt="BillieJH" />
            <div className="buttons">
                <a href="https://vimeo.com/334011585" target="_blank" rel="noreferrer">2D Showreel</a>
                <a href="https://vimeo.com/372137247" target="_blank" rel="noreferrer">3D Showreel</a>
            </div>
        </div>
    )
}

export default Intro;