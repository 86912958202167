import './style.css'
import KinaestheticEmpathy from '../../images/kinaesthetic-empathy.png'
import Boat from '../../images/meridians.png'
import PostTruth from '../../images/post-truth.png'

const Projects = () => {
    return (
        <div className="projects">
            <div className="content">
                <div className="project">
                    <a href="https://vimeo.com/276665908" target="_blank" rel="noreferrer">
                        <img src={KinaestheticEmpathy} alt="Kinaesthetic Empathy" />
                    </a>
                    <section>
                        <h1>Kinaesthetic Empathy</h1>
                        {//<p>An exploration of hands or touch or something</p>
                        }
                    </section>
                </div>
                <div className="project">
                    <section>
                        <h1>Meridians</h1>
                        <p>My third year final project</p>
                    </section>
                    <img src={Boat} alt="Meridians"></img>
                </div>
                <div className="project">
                    <img src={PostTruth} alt="Post-truth"></img>
                    <section>
                        <h1>Post-Truth</h1>
                        {//<p>Look at him go hahaha weeeeeeee</p>
                        }
                    </section>
                </div>
            </div>
        </div>
    )
}

export default Projects;