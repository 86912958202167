import './variables.css';
import './App.css';

import Homepage from './pages/homepage'
import Nav from './components/nav'

function App() {
  return (
    <div className="App">
      <Nav />
      <Homepage />
    </div>
  );
}

export default App;